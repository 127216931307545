<template>
  <div class="audit-wrap">
    <div v-cloak>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 审核文档
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-container>
      <el-main>
        <p class="title">标题：</p>
        <el-input v-model="checkData.title" placeholder="请输入标题" class="title-input"></el-input>
        <p class="title">正文内容：</p>
        <TEditor ref="editor" v-model="value" />
      </el-main>
      <el-aside width="300px">
        <p class="title">分类：</p>
        <el-tag size="medium" closable @close="handleClose()" v-if="cate">{{cate.name}}</el-tag>
        <div class="cate-list">
          <el-button
            size="mini"
            v-for="(item, index) in cateArr"
            :key="index"
            @click="chooseCate(item)"
          >{{item.name}}</el-button>
        </div>
        <p class="title">关键词：</p>
        <el-input v-model="keywords" placeholder="请输入关键词" type="textarea" autosize></el-input>
        <div class="keywords">
          <el-button
            size="mini"
            v-for="(item, index) in keywordsArr"
            :key="index"
            @click="chooseKeyword(item)"
          >{{item}}</el-button>
        </div>
        <p class="title">职业标签：</p>
        <el-input v-model="tag" placeholder="请输入职业标签" type="textarea" autosize></el-input>
        <div class="keywords">
          <el-button
            size="mini"
            v-for="(item, index) in tagArr"
            :key="index"
            @click="chooseTag(item)"
          >{{item}}</el-button>
        </div>
        <p class="title">排序：</p>
        <div class="sort">
          <el-button
            size="mini"
            :class="{'active':item.id==sort}"
            v-for="(item, index) in sortArr"
            :key="index"
            @click="choosesort(item)"
          >{{item.name}}</el-button>
        </div>
        <el-button type="primary" @click="auditBtn" class="auditBtn">通过审核开始启用</el-button>
        <p @click="auditErrorBtn" class="auditErrorBtn">审核不通过</p>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import { startCheck, getDocType, documentdraftSave } from "../../api/index";
import to , {tagArr} from "../../assets/js/common";
export default {
  name: "audit",
  data() {
    return {
      id: "",
      title: "",
      cate: "",
      keywords: "工作总结,汇报,述职,报告,年终,汇报,年终,个人述职,项目汇报",
      sort: 1,
      value: "富文本富文本",
      checkData: "",
      tag: "",
      sortArr: [
        {
          id: 0,
          name: "靠前"
        },
        {
          id: 1,
          name: "居中"
        },
        {
          id: 2,
          name: "靠后"
        }
      ],
      cateArr: [],
      keywordsArr: [
        "简约",
        "黑金",
        "扁平",
        "卡通",
        "商务",
        "中国风",
        "科技",
        "创意",
        "炫酷",
        "清新",
        "复古",
        "手绘"
      ],

      tagArr: tagArr
    };
  },
  created() {
    let id = this.$route.query.id || "";
    this.id = id;
    console.log("id", id);
    this.getstartCheck();
    this.getDocCategoryFn();
  },
  methods: {
    async getstartCheck() {
      let [err, result] = await to(
        startCheck({
          id: this.id || ""
        })
      );
      if (err) {
        console.log(11);
      } else {
        if (result.code == 0) {
          let data = result.data;
          this.checkData = data;
          data.keywords && (this.keywords = data.keywords);
          if (data.sort == 0 || data.sort) this.sort = data.sort;
          data.categoryExpression && (this.tag = data.categoryExpression);
          // data.keywords && (this.keywords = data.keywords)
          // keywords
          // keywords
          // status
          // categoryExpression
          setTimeout(() => {
            this.value = ""; // 富文本编辑器内容;
            this.$refs.editor.initTiny(data.content); // 重新设置编辑器内容
          }, 500);
        }
      }
    },
    async getDocCategoryFn() {
      let [err, result] = await to(
        getDocType({
          parentId: 10008,
          documentType: 2
        })
      );
      if (err) {
        console.log(11);
      } else {
        if (result.code == 0) {
          console.log(1);
          this.cateArr = result.data;
          this.cate = result.data[0];
        }
      }
    },
    handleClose() {
      this.cate = "";
    },
    chooseCate(item) {
      this.cate = item;
    },
    chooseKeyword(item) {
      let keywords = this.keywords.replace("，", ",");
      keywords = keywords.split(",");
      console.log("keywords", keywords);
      if (keywords.indexOf(item) > -1) return;
      let tag = true;
      keywords.forEach(element => {
        if (element == item) {
          tag = false;
        }
      });
      if (tag) {
        keywords.push(item);
        this.keywords = keywords.join(",");
        this.keywords = this.keywords.replace(/^,/, "");
      }
    },
    chooseTag(item) {
      this.tag = item;
    },
    choosesort(item) {
      this.sort = item.id;
    },
    async auditBtn() {
      let tag = this.tag;
      let title = this.checkData.title;
      let content = this.$refs.editor.getText();
      let sort = this.sort;
      let keywords = this.keywords;
      console.log(title, content, keywords, sort, tag);
      console.log("审核");
      let [err, result] = await to(
        documentdraftSave({
          id: this.checkData.id,
          title,
          content,
          color: this.checkData.color,
          documentType: 2,
          categoryId: this.cate.id,
          keywords,
          description: this.checkData.description,
          categoryExpression: tag,
          sort,
          status: 1
        })
      );
      console.log("result", result);
      if (err) {
        console.log(11);
      } else {
        if (result.code == 0) {
          console.log(1);
          this.$message({
            message: "审核通过",
            type: "success"
          });
          setTimeout(() => {
            // const isProduction = process.env.VUE_APP_CURRENTMODE === 'prod';
            // if(isProduction)
            window.location.href = "./audit";
          }, 1000);
        }
      }
    },
    async auditErrorBtn() {
      let tag = this.tag;
      let title = this.checkData.title;
      let content = this.$refs.editor.getText();
      let sort = this.sort;
      let keywords = this.keywords;
      console.log(title, content, keywords, sort, tag);
      console.log("审核");
      let [err, result] = await to(
        documentdraftSave({
          id: this.checkData.id,
          title,
          content,
          color: this.checkData.color,
          documentType: 2,
          categoryId: this.cate.id,
          keywords,
          description: this.checkData.description,
          categoryExpression: tag,
          sort,
          status: 2
        })
      );
      console.log("result", result);
      if (err) {
        console.log(11);
      } else {
        if (result.code == 0) {
          console.log(1);
          this.$message.error("审核不通过");
          setTimeout(() => {
            //  const isProduction = process.env.VUE_APP_CURRENTMODE === 'prod';
            // if(isProduction)
            window.location.href = "./audit";
          }, 1000);
        }
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.audit-wrap {
  .el-container {
    margin-top: 10px;
    padding: 30px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    .el-main {
      padding-left: 0;
      padding-top: 0;
      .title {
        padding-bottom: 10px;
        color: #606266;
        font-weight: 600;
      }
    }
    .el-tag {
      margin-top: 5px;
    }
  }
  .cate-list,
  .keywords {
    padding-top: 5px;
    padding-bottom: 10px;
    button {
      margin-left: 0;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }
  .el-aside {
    .title {
      padding-bottom: 10px;
      color: #606266;
      font-weight: bold;
    }
  }
  .auditBtn {
    margin-top: 30px;
    width: 100%;
  }
  .auditErrorBtn {
    margin-top: 10px;
    text-align: center;
    color: #66b1ff;
    cursor: pointer;
  }
  .title-input {
    margin-bottom: 10px;
  }
  .sort {
    .active {
      color: #409eff;
      border-color: #c6e2ff;
      background-color: #ecf5ff;
    }
  }
}
</style>
